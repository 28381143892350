import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "iOSDC Japan 2020 にメドレーが協賛しました",
  "date": "2020-10-02T09:14:43.000Z",
  "slug": "entry/2020/10/02/181443",
  "tags": ["medley"],
  "hero": "./2020_10_02.png",
  "heroAlt": "iOSDC 2020"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`皆様こんにちは。インキュベーション本部エンジニアの濱中です。
9/19〜21 に`}<a parentName="p" {...{
        "href": "https://iosdc.jp/2020/"
      }}>{`iOSDC Japan 2020`}</a>{`（以下 iOSDC）が開催されました。`}<a parentName="p" {...{
        "href": "/entry/2020/09/14/181100"
      }}>{`先日の記事`}</a>{`の通り、メドレーは 2017 年より iOSDC に`}<a parentName="p" {...{
        "href": "https://iosdc.jp/2020/sponsor.html"
      }}>{`協賛`}</a>{`しております。
メドレーでは、Swift を利用してオンライン診療／服薬指導アプリ「CLINICS」iOS 版の開発をしています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://apps.apple.com/jp/app/clinics-%E3%82%AF%E3%83%AA%E3%83%8B%E3%82%AF%E3%82%B9/id1106261604?uo=4&at=10l8JW&ct=hatenablog"
      }}>{`CLINICS(クリニクス)　オンライン診療・服薬指導アプリ`}</a></p>
    <p>{`5 回目となる今回は、初のオンライン開催となり、主にニコニコ生放送、Discord 上で発表・コミュニケーションが行われました。私が iOS 版 CLINICS の開発に携わっている縁で、今回スポンサー枠として iOSDC に参加させていただきましたので紹介させていただきます。`}</p>
    <h1>{`イベント全体について`}</h1>
    <p>{`オンライン開催となったため、会場の様子や企業ブースなど、雰囲気の伝わる写真をお届けできないのが残念ですが、発表の主会場となったニコニコ生放送では、終始穏やかな雰囲気でありつつも活発にコメントがなされ、大いに盛り上がっていました。`}</p>
    <p>{`前回同様、初日は day 0 として夕方から、2 日目以降は朝〜夕方まで、最大 5 つのチャンネルで並行して発表が行われました。セッションについては事前に録画したものを放送し、LT のみリモートにてリアルタイム発表という形式となっていました。`}</p>
    <p>{`質疑応答については、各発表の終了直後に Discord チャンネルに発表者が待機して対応していました。初のオンライン開催ということで、イントロダクション動画をはじめとし、各所で積極的なフィードバック・コミュニケーションが奨励されていたように思います（なお、イントロダクション・スポンサー紹介の各動画はナレーションが声優の緒方恵美さん・三石琴乃さんと、とても豪華でした。生放送中のコメントや、`}<a parentName="p" {...{
        "href": "/entry/2018/09/13/175702"
      }}>{`18 年の弊社ブログ`}</a>{`を見る限りは毎年恒例のようですね。すごいです！）。`}</p>
    <iframe src="https://www.youtube.com/embed/XBytN1a7MPg" width="560" height="315" frameBorder="0" allowFullScreen=""></iframe>
    <h1>{`セッションについて`}</h1>
    <p>{`昨年 iOS13 とともに発表された SwiftUI への移行や、コード移行・モジュール分割等、プロジェクトの最適化についてのトピックが多かったように思います。`}</p>
    <p>{`SwiftUI は、従来 Storyboard で設計していた UI をコードベースで記述できる画期的なフレームワークですが、SwiftUI を使ったアプリは iOS13 未満の端末では利用できなくなってしまうこともあり、アプリの公開対象を広めにもっておきたい場合はなかなか乗り換えづらい印象でした。2020 年 6 月現在で iOS13 のシェアが 9 割以上となったことで、ちょうど iOSDC での発表トピックを決めるころに導入作業を行った（かつ苦労した）というケースが多かったのかな、と思います。`}</p>
    <p>{`以下、視聴したセッションのうち気になったものをいくつかご紹介いたします。`}</p>
    <h2>{`オープンソースの AltSwiftUI の発表`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="オープンソースの AltSwiftUI の発表" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Ffortee.jp%2Fiosdc-japan-2020%2Fproposal%2F3ab710d8-4b19-4a57-b14c-8e23368dec27" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://fortee.jp/iosdc-japan-2020/proposal/3ab710d8-4b19-4a57-b14c-8e23368dec27">fortee.jp</a></cite>
    <p>{`楽天のエンジニアの方による、SwiftUI の提供するネイティブコンポーネントに対応しつつ、iOS11 以上で利用可能なオープンソースのフレームワーク`}<a parentName="p" {...{
        "href": "https://github.com/rakutentech/AltSwiftUI"
      }}>{`AltSwiftUI`}</a>{`（`}<a parentName="p" {...{
        "href": "https://altswiftui.com/"
      }}>{`公式 Doc`}</a>{`）の開発についてのセッションでした。`}</p>
    <p>{`iOS12 以下の対応を切らずに SwiftUI への乗り換えを進められる（かつ本家と違ってオープンソースである）便利さもそうですが、別途フレームワークが出来上がってしまうあたりに、SwiftUI への移行対応への苦労がしのばれる内容でもありました（ストアにある楽天提供の iOS アプリの数を考えると乗り換えコストが大変そう…）。`}</p>
    <h2>{`「それ、自動化できますよ」: note を支えるワークフロー大全`}</h2>
    <iframe id="talk_frame_667616" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/44078ad96961436287ee7c2af9d5ad87" width="710" height="371" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/laprasdrum/ios-dev-workflow-automation-for-note">speakerdeck.com</a></cite>
    <p>{`改修要望が上がってから、実際に改修を行ってアプリをリリースするまでの作業をできる限り自動化した、というセッションです。CLINICS でも、「証明書の有効期限確認、プッシュからのテスト、マージからのリリース準備」は Bitrise のトリガを利用して自動化しています。`}</p>
    <p>{`上記のような、GitHub 上でのアクション（プッシュ、マージなど）をトリガとする自動化はよく聞く話ではあるのですが、Slack のポストに特定のスタンプつけると Issue 化、はちょっと目新しくて面白いなと思いました（気をつけないと表記揺れで同じような Issue が乱立しそうですが）。`}</p>
    <p>{`Issue もきちんと管理しておかないとトラブルの起きやすい部分ですよね。起票者と実装者の間でボールが浮いてしまったり、プロジェクトに紐づいていなかったために対応から洩れてしまったり…。`}</p>
    <p>{`また、このスライドですが終始手書きの挿絵がかわいくて、そういった意味でもコメント欄が盛り上がっていたのが印象的でした。`}</p>
    <h2>{`100 人でアプリをリファクタリングして見えてきた、最強の iOS アプリ設計に求められること`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="100 人でアプリをリファクタリングして見えてきた、最強の iOS アプリ設計に求められること" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Ffortee.jp%2Fiosdc-japan-2020%2Fproposal%2Fa90d763e-d8d4-4d4f-b5ca-927ad253bc2a" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://fortee.jp/iosdc-japan-2020/proposal/a90d763e-d8d4-4d4f-b5ca-927ad253bc2a">fortee.jp</a></cite>
    <p>{`アプリを長期に運用していくとほぼ必須となる課題でありながら、人ごとに基準が曖昧だったり、機能開発におされて対応が後手後手になったり…と、何かとつらい話をよく聞くソースコードのリファクタリングに関するセッションでした。`}</p>
    <p>{`同じ状態のソースコードを多数のエンジニアがリファクタした結果を比較することで、「良いリファクタリングのための考え方」とは何か？を模索した内容です。`}</p>
    <p>{`ビューとロジックの分割をしっかり行う、というのは PR レビューでエンジニアが口を酸っぱくしてよく言われることではありますが、「ロジックの中でも、アプリの仕様に依存するものとそうでない普遍的なものは分離すべき」というアイデアは個人的には眼から鱗が落ちるものでした。`}</p>
    <p>{`また、これを説明するリバーシの具体例（「対戦相手が人間か AI か」はアプリ仕様に依存するロジック、「そこに石を置けるか」は普遍的なロジック＝リバーシのルールそのもの）も非常にわかりやすかったです。`}</p>
    <p>{`そのほか、React / Redux でフロントエンド開発を行っている人にはお馴染みの Action / Reducer を使った単一方向のデータフローの導入なども紹介されています。`}</p>
    <h2>{`新規機能開発からモジュール分割を始めてみる`}</h2>
    <iframe id="talk_frame_667466" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/be43e85126b546cc82a1ca471dda6969" width="710" height="399" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/rizumi/xin-gui-ji-neng-kai-fa-karamoziyurufen-ge-woshi-metemiru">speakerdeck.com</a></cite>
    <p>{`一つのアプリが長期間運用されていくなかで、複数の機能が統合されたスーパーアプリになることがあります。そうなった場合、ソースコードが肥大化 → ビルド・テストも長大化、となってメンテナンス性が低下するため、対策としてコードを分割してテストやビルドの単位を小さくする必要があります。`}</p>
    <p>{`いきなりアプリ全体をモジュールに分割するのは時間がかかるため、本セッションではまず第一段階として新規に開発する機能を別モジュールとして実装し、その時得た知見について触れられていました。`}</p>
    <p>{`「分割したモジュール側でのサードパーティ製フレームワークのリンク方法（※リンクを正しく行わないと、ビルドして動作はするのにアーカイブに失敗してリリースできなくなる）」などは、今後の開発にモジュール分割を取り入れていく際に参考になりそうです。`}</p>
    <h2>{`Swift で始める静的解析`}</h2>
    <iframe id="talk_frame_667483" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/fdf9c396f7c84d7c9ebc0774947fd6a7" width="710" height="399" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/matsuji/swiftdeshi-merujing-de-jie-xi">speakerdeck.com</a></cite>
    <p>{`Swift ソースコードからの構文木の生成、解析を行うライブラリ SwiftSyntax の紹介と、それを用いたソースコード重複検出機能の実装についてのセッションでした。`}</p>
    <p>{`普段 Xcode を始めとした IDE で開発していると、コード重複、不要なローカル変数、型や Nullable の不一致に変数リファクタリング…等々の便利な機能を気軽に使えてしまいますが、その裏の動作を改めて一つずつ具体的に追っていくと、そのありがたみが身に染みます…。`}</p>
    <p>{`静的解析そのものは Swift に限らず様々な言語のソースコードに対して適用できるトピックではあるのですが、普段何気なく使ってしまう IDE の機能について考えるよい機会になったため、紹介させていただきました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`ちょうど業務でも iOS アプリ開発を担当していることもあり、興味深い知見が得られ、よい経験となりました。また、事前録画形式になったことで発表の構成がよく練られ、結果として聞きやすく（皆様かなり気をつけてゆっくり発声されていました）、個性のある発表が多かったように思います。`}</p>
    <p>{`今回、初のオンラインでの開催ということで、運営委員会の皆様もいろいろと苦労されていらっしゃるようでした。ただ、その甲斐あってか当日の進行はスムーズで、会場はとても盛り上がっていました。関係者の皆様、本当にお疲れ様でした。`}</p>
    <p>{`情勢を踏まえ、来年度の開催可否・形式は未定とのことでしたが、オンライン・対面のそれぞれの良さを取り入れつつ、より多くの人が参加できる形態になっているとよいなと思います。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCF-W8FRL7d_9konHA9eNObA"
      }}>{`公式 YouTube チャンネル`}</a>{`で過去の発表を視聴できますので、ご興味のある方はぜひどうぞ（今年の発表分も一ヶ月ほどしたら公開されるとのことでした）。`}</p>
    <p>{`またメドレーでは iOS / Android ネイティブアプリ開発エンジニアを募集しています。興味がある方、ぜひお気軽にお話しましょう!`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集の一覧 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      